import Vue from "vue";
import { AclInstaller, AclCreate, AclRule } from "vue-acl";

import router from "@/router";

Vue.use(AclInstaller);

const LOGGED_ROLE_DEFAULT = ["public", "Logged"];

let initialRole = LOGGED_ROLE_DEFAULT;

function _setProfiles() {
  let profiles = [];

  if (localStorage.getItem("userInfo")) {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    profiles.push(userInfo.profile.name);
  }
  if (profiles && profiles.length > 0) {
    profiles.push(...LOGGED_ROLE_DEFAULT);
    initialRole = profiles;
  } /*else {
    initialRole = LOGGED_ROLE_DEFAULT;
  }*/
}

_setProfiles();

export default new AclCreate({
  initial: initialRole,
  notfound: "/not-authorized",
  router,
  acceptLocalRules: true,
  middleware: async (acl) => {
    _setProfiles();
    acl.change(initialRole);
  },

  globalRules: {
    isLogged: new AclRule("Logged").generate(),
    public: new AclRule("public").generate(),
    isAdmin: new AclRule("Administrador").generate(),
    isSupervisor: new AclRule("Supervisor").or("Administrador").generate(),
    isRequester: new AclRule("Solicitante")
      .or("Administrador")
      .or("Supervisor")
      .generate(),
    isRequesterOrDesigner: new AclRule("Solicitante")
      .or("Projetista")
      .or("Supervisor")
      .or("Administrador"),
    isDesigner: new AclRule("Projetista")
      .or("Supervisor")
      .or("Administrador")
      .generate(),
  },
});
