import axios from "../../../sirius/index.js";
import store from "../../../../store/store.js";

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

export default {
  init() {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        // const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        // if (status === 401) {
        if (response && response.status === 401) {
          if (!isAlreadyFetchingAccessToken) {
            isAlreadyFetchingAccessToken = true;
            store.dispatch("auth/fetchAccessToken").then((access_token) => {
              isAlreadyFetchingAccessToken = false;
              onAccessTokenFetched(access_token);
            });
          }

          const retryOriginalRequest = new Promise((resolve) => {
            addSubscriber((access_token) => {
              originalRequest.headers.Authorization = "Bearer " + access_token;
              resolve(axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }
        return Promise.reject(error);
      }
    );
  },
  login(login, password) {
    return axios.post("/users/login", { login, password });
  },
  registerUser(name, email, pwd) {
    return axios.post("/users", {
      displayName: name,
      email: email,
      password: pwd,
    });
  },
  forgotPassword(email) {
    return axios.post("/users/word", { email });
  },
  refreshToken() {
    return axios.post("/users/refresh-token", {
      accessToken: localStorage.getItem("accessToKen"),
    });
  },
};
