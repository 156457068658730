/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      // =============================================================================
      // MAIN CNC LAYOUT ROUTES
      // =============================================================================
      path: "/admin",
      component: () => import("./layouts/main/Main.vue"),
      meta: {
        authRequired: true,
        rule: "public",
      },
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "addrequest",
          name: "addrequest",
          component: () => import("./views/AddRequest.vue"),
          meta: {
            pageTitle: "Novo Desenvolvimento",
            authRequired: true,
            rule: "isRequester",
          },
        },
        {
          path: "release",
          name: "release",
          component: () => import("./views/ReleaseProject.vue"),
          meta: {
            pageTitle: "Liberar Projeto",
            authRequired: true,
            rule: "isAdmin",
          },
        },
        {
          path: "import",
          name: "import",
          component: () => import("./views/Import.vue"),
          meta: {
            pageTitle: "Importar Projetos",
            authRequired: true,
            rule: "isAdmin",
          },
        },
        {
          path: "addorder",
          name: "addorder",
          component: () => import("./views/AddOrder.vue"),
          meta: {
            pageTitle: "Nova Requisição",
            authRequired: true,
            rule: "isRequester",
          },
        },
        {
          path: "cart",
          name: "cart",
          component: () => import("./views/Cart.vue"),
          meta: {
            pageTitle: "Carrinho",
            authRequired: true,
            rule: "isRequester",
          },
        },
        {
          path: "order",
          name: "order",
          component: () => import("./views/Order.vue"),
          meta: {
            pageTitle: "Nova Requisição",
            authRequired: true,
            rule: "isRequester",
          },
        },
        {
          path: "profile",
          name: "profile",
          component: () => import("./views/Profile.vue"),
          meta: {
            pageTitle: "Perfil",
            authRequired: true,
            rule: "isLogged",
          },
        },
        {
          path: "pendingorders",
          name: "pendingorders",
          component: () => import("./views/PendingOrders.vue"),
          meta: {
            pageTitle: "Requisições Pendentes",
            authRequired: true,
            rule: "isSupervisor",
          },
        },
        {
          path: "projects",
          name: "projects",
          component: () => import("./views/Projects.vue"),
          meta: {
            pageTitle: "Catálogo de Peças",
            authRequired: true,
            rule: "isRequesterOrDesigner",
          },
        },
        {
          path: "kanban",
          name: "kanban",
          component: () => import("./views/Kanban.vue"),
          meta: {
            //pageTitle: "KanBan",
            authRequired: true,
            rule: "isLogged",
          },
        },
        {
          path: "myorders",
          name: "myorders",
          component: () => import("./views/MyOrders.vue"),
          meta: {
            pageTitle: "Minhas Requisições",
            authRequired: true,
            rule: "isRequester",
          },
        },
        {
          path: "users",
          name: "user",
          component: () => import("./views/Users.vue"),
          meta: {
            pageTitle: "Usuários",
            authRequired: true,
            rule: "isAdmin",
          },
        },
        {
          path: "export",
          name: "export",
          component: () => import("./views/Export.vue"),
          meta: {
            pageTitle: "Exportar Projetos",
            authRequired: true,
            rule: "isAdmin",
          },
        },
      ],
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/",
          name: "page-login",
          alias: "login",
          component: () => import("@/views/pages/Login.vue"),
          meta: {
            mustBeDislogged: true,
            rule: "public",
          },
        },
        {
          path: "/pages/error-404",
          name: "page-error-404",
          component: () => import("@/views/pages/Error404.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/not-authorized",
          name: "page-not-authorized",
          component: () => import("@/views/pages/NotAuthorized.vue"),
          meta: {
            rule: "public",
          },
        },
        {
          path: "/auth/confirm",
          name: "reset",
          component: () => import("@/views/Reset.vue"),
          meta: {
            rule: "public",
          },
        },
      ],
    },
    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/pages/error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");

  if (appLoading) {
    appLoading.style.display = "none";
  }
});

router.beforeEach((to, from, next) => {
  if (store.state.auth.isUserLoggedIn()) {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    if (loggedUser.shouldChangePassword && from.name == "profile") {
      router.go();
      return false;
    } else if (loggedUser.shouldChangePassword && to.name != "profile") {
      router.push({ path: "/admin/profile" });
    } else if (to.meta.mustBeDislogged) {
      router.push({ path: "/admin/kanban" });
    }
  } else if (to.meta.authRequired) {
    router.push({ path: "/login", query: { to: to.path } });
  }
  return next();
});

export default router;
